import axios, { AxiosResponse } from "axios";
import { SignupState } from "@/models/signup-state";
import { SignupResult } from "@/models/signup-result";
import { ConfirmSignupResult } from "@/models/confirm-signup-result";
import { Invitation } from "@/models/invitation";
import { ActivateAccountResult } from "@/models/activate-account-result";
import { SubscriptionPlan } from "@/models/subscription-plan";
import { SubscriptionSetup } from "@/models/subscription-setup";
import { Subscription } from "@/models/subscription";
import moment from "moment";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? `https://api.aware24-app.com/app/api/v1`
    : `${process.env.VUE_APP_SERVER_URL}/api/v1`;

export const SUPPORTED_REGIONS: Record<string, string> = {
  LA: 'Laos',
  MM: 'Myanmar',
  NP: 'Nepal',
  KH: 'Cambodia',
  TW: 'Taiwan',
  MY: 'Malaysia',
  TH: 'Thailand',
  SG: 'Singapore',
  BN: 'Brunei Darussalam',
};

export default class SignupApi {
  static createAccount(info: SignupState): Promise<SignupResult> {
    const members = info.members.map((member) => ({
      ...member,
      birthdate:
        member?.birthdate instanceof Date
          ? member?.birthdate
          : moment.utc(member?.birthdate, "DD / MM / YYYY").toJSON(),
    }));

    const headOfFamily = members.find(({ head }) => head);

    return axios
      .post<SignupResult, AxiosResponse<SignupResult>>(
        `${BASE_URL}/${info.region}/user-registration`,
        {
          dependencies: members.filter(({ head }) => !head),
          username: info.email, // Also submit email as our username
          password: info.password,
          code: info.invitationCode,
          email: info.email,
          lastname: headOfFamily?.lastname,
          middlename: headOfFamily?.middlename,
          firstname: headOfFamily?.firstname,
          birthdate: headOfFamily?.birthdate,
          phone: headOfFamily?.phone,
        }
      )
      .then((result) => result.data)
      .catch((err) => {
        throw err.response?.data;
      });
  }

  static confirmRegistration(info: SignupState): Promise<ConfirmSignupResult> {
    return axios
      .put<ConfirmSignupResult, AxiosResponse<ConfirmSignupResult>>(
        `${BASE_URL}/${info.region}/user-registration/confirmation`,
        {
          username: info.email,
          code: info.verificationCode,
        }
      )
      .then(() => ({ success: true }))
      .catch(() => {
        return { success: false };
      });
  }

  static resendConfirmation(info: SignupState): Promise<ConfirmSignupResult> {
    return axios
      .post<ConfirmSignupResult, AxiosResponse<ConfirmSignupResult>>(
        `${BASE_URL}/${info.region}/user-registration/confirmation`,
        {
          username: info.email,
        }
      )
      .then(() => ({ success: true }))
      .catch(() => {
        return { success: false };
      });
  }

  static getInvitationInfo(code: string, region: string): Promise<Invitation> {
    return axios
      .get(`${BASE_URL}/${region}/organization/invites`, {
        params: { code },
      })
      .then((result) => result.data)
      .catch((err) => {
        throw err.response?.data;
      });
  }

  static activateAccount(info: SignupState): Promise<ActivateAccountResult> {
    return axios
      .put(`${BASE_URL}/${info.region}/subscription/${info.email}`)
      .then((result) => result.data)
      .catch((err) => {
        throw err.response?.data;
      });
  }

  static getPlans(): Promise<SubscriptionPlan[]> {
    return axios
      .get(`${BASE_URL}/subscription/plans`)
      .then((result) => result.data.plans)
      .catch((err) => {
        throw err.response?.data;
      });
  }

  static calculatePlan(params: SubscriptionSetup): Promise<Subscription> {
    return axios
      .get(`${BASE_URL}/subscription/plans/calculated`, {
        params,
      })
      .then((result) => result.data)
      .catch((err) => {
        throw err.response?.data;
      });
  }

  static checkout(
    username: string,
    setup: SubscriptionSetup,
    region: string
  ): Promise<Subscription> {
    return axios
      .post(`${BASE_URL}/${region}/subscription/payment/request`, {
        username,
        ...setup,
      })
      .then((result) => result.data)
      .catch((err) => {
        throw err.response?.data;
      });
  }
}
